import React from "react";
import dynamic from "next/dynamic";

const Header = dynamic(() => import("./Header"));
const Footer = dynamic(() => import("./Footer"));

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
};

function ContentLayoutFooterNoChartreuse({
  children,
  title,
}: ContentLayoutProps) {
  return (
    <>
      <Header title={title} />
      <div className="min-h-[calc(100vh-64px)]">{children}</div>
      <Footer />
    </>
  );
}

export default ContentLayoutFooterNoChartreuse;
